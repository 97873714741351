<template>
  <div class="wrapper">
    <div class="left" v-loading="treeLoading">
      <div class="title" style="padding: 0; border-bottom: 0px;">
        <div class="tree-title">
          货品分类
          <el-button
            type="primary"
            size="mini"
            style="float: right; margin-top: 18px; margin-right: 15px"
             @click="handleAdd"
            >新增分类</el-button
          >
        </div>
        <!-- <el-button type="primary" @click="handleAdd">新增货品分类</el-button> -->
      </div>
      <template v-if="treeData.length">
        <div v-for="item in treeData" :key="item.type">
          <div style="font-size: 14px; line-height: 30px; text-indent: 20px; font-weight: bold;">{{item.group_name}}</div>
          <el-tree
            class="tree-line"
            :indent="0"
            :key="Math.random()"
            node-key="goods_type_id"
            :current-node-key="currentKey"
            :default-expand-all="true"
            :data="item.list"
            :props="defaultProps"
            :highlight-current="true"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-if="data.hierarchy_id === 1">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="系统默认分类不可添加子分类"
                  placement="right-end"
                >
                  <span style="font-size: 12px; color: #999"
                    >系统默认<i class="el-icon-warning"></i
                  ></span>
                </el-tooltip>
              </span>
            </span>
          </el-tree>
        </div>
      </template>
      <template v-else>
        <div
          style="
            text-align: center;
            font-size: 14px;
            margin-top: 20px;
            color: #666;
          "
        >
          暂无数据
        </div>
      </template>
    </div>
    <div class="right" v-loading="loading2">
      <div class="title">
        <template v-if="!isAdd">
          <div class="name">{{ currItem.goods_type_name }}</div>
          <i
          slot="reference"
          class="el-icon-delete"
          style="margin-top: 3px"
          @click="handleDele"
          ></i>
          <!-- <el-popconfirm title="确定要删除该分类吗？" @onConfirm="handleDele">
            <i
              slot="reference"
              class="el-icon-delete"
              style="margin-top: 3px"
            ></i>
          </el-popconfirm> -->
        </template>
        <div style="margin-left: auto" v-if="isAdd">
          <el-button type="primary" @click="handleSave" :loading="loading"
            >保存</el-button
          >
          <el-button @click="handleCancel" :loading="loading">取消</el-button>
        </div>
        <el-button
          type="primary"
          style="margin-left: auto"
          @click="handleSave"
          :loading="loading"
          v-if="!isAdd"
          >保存</el-button
        >
      </div>
      <div style="padding: 20px">
        <div class="form">
          <div class="form-title">基本信息</div>
          <!-- <div v-html="htmlNode" @click="handleGoods($event)"></div> -->
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="品类名称" required>
              <el-input
                v-model="formInline.goods_type_name"
                @input="handleTipToSave"
                placeholder="请输入"
                :disabled="currItem.hierarchy_id === 1 ? true : false"
                clearable
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="上级分类">
              <el-select v-model="formInline.pid" placeholder="请选择" :disabled="currItem.hierarchy_id? true: false" clearable filterable>
                <template v-for="item in select">
                  <el-option :label="item.goods_type_name" :value="item.goods_type_id" :key="item.goods_type_id" v-if="item.hierarchy_id !== 1">
                    <span :style="{marginLeft: item.span * 8 +'px'}">{{item.goods_type_name}}</span>
                  </el-option>
                </template>
              </el-select>
            </el-form-item> -->
            <el-form-item label="品类分组" label-width="100px" required>
              <el-select v-model="formInline.type"  :disabled="currItem.hierarchy_id ? true : false" @change="handleGroupSelect">
                <template v-for="item in treeData">
                  <el-option :key="item.type" :label="item.group_name" :value="item.type"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item
              label="上级分类"
              label-width="100px"
              @change="handleTipToSave"
            >
              <SelectTree
                :disabled="currItem.hierarchy_id ? true : false"
                :value="formInline.pid"
                :props="props"
                :options="topSelectData"
                :clearable="isClearable"
                :accordion="isAccordion"
                @getValue="getValue($event)"
              />
            </el-form-item>
            <el-form-item label="销售方式" label-width="100px" required>
              <el-select
                v-model="formInline.sales_type"
                placeholder="请选择"
                :disabled="currItem.hierarchy_id ? true : false"
                @change="handleSelect"
                clearable
                filterable
              >
                <el-option label="销售按克" :value="10"></el-option>
                <el-option label="销售按件" :value="20"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="黄色预警">
              <el-input v-model="formInline.user" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
            <el-form-item label="红色预警" style="color: yellow;">
              <el-input v-model="formInline.user" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
            <el-form-item label="黑色预警">
              <el-input v-model="formInline.user" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
            </el-form-item> -->
          </el-form>
        </div>
        <!-- <div class="btns">
          <el-button type="primary" @click="type = 1">分类属性</el-button>
          <el-button @click="type = 2" v-if="!isAdd">操作日志</el-button>
        </div> -->
        <el-tabs v-model="type" @tab-click="handleClick">
          <el-tab-pane label="分类属性" name="1"></el-tab-pane>
          <el-tab-pane label="操作日志" name="2" v-if="!isAdd"></el-tab-pane>
        </el-tabs>
        <div
          class="table"
          v-if="type === '1'"
          style="border-radius: 6px; border: 1px solid #ddd; overflow: hidden;"
        >
          <el-table stripe :data="attrList" :row-class-name="tableRowClassName">
            <el-table-column
              prop="attr_type_name"
              label="属性分类"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="date" label="启用状态">
              <template slot-scope="scope">
                <template v-for="item in scope.row.data">
                  <el-tag
                    style="margin: 8px; line-height: 32px"
                    :key="item.attr_id"
                    :type="item.is_use === 10 ? '' : 'info'"
                    >{{ item.attr_name }}</el-tag
                  >
                </template>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table" style="border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; border-radius: 6px;" v-else>
          <el-table stripe :data="tableData2.list" key="2" height="480">
            <!-- <el-table-column
              prop="date"
              label="序号"
              width="100"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              prop="create_time"
              label="操作时间"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="msg"
              label="操作记录"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="create_user_name" label="操作人" width="150" align="center">
            </el-table-column>
          </el-table>
        </div>
        <div class='pagina' v-if="type === '2'">
          <el-pagination
          :current-page="page"
            background
            @size-change="handleSizeChange"
            layout='total, sizes, prev, pager, next, jumper'
            :total='tableData2.total'
            :page-size='15'
            :page-sizes="[15, 30, 40, 50]"
            @current-change='handleCurrentChange'
          ></el-pagination>
      </div>
      </div>
    </div>
    <el-dialog
      title="编辑货品属性"
      :visible.sync="dialogVisible"
      :destroy-on-close="true"
      width="50%"
    >
      <div class="tip">
        交互类型为<span class="red">自动计算</span>的属性，计算公式内属性的<span class="red">应用</span>与<span class="red">必填</span>的勾选自动与其保持一致。
      </div>
      <div
        class="table"
        style="
          border: 1px solid #ddd;
          border-radius: 6px;
          overflow: hidden;
          border-bottom: 0px;
        "
      >
        <el-table stripe :data="currData" key="3" height="500">
          <el-table-column prop="date" label="应用" width="100" align="center">
            <template slot-scope="scope">
              <el-checkbox
                @change="handleChange($event, scope.row)"
                v-model="scope.row.is_use"
                :true-label="10"
                :false-label="20"
                :disabled="
                  scope.row.disabled ||
                  (currItem.hierarchy_id === 1 && scope.row.hierarchy_id === 1)
                "
              >
              </el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="attr_name"
            label="属性"
            width="150"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="date" label="交互" width="180" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 10">输入文本</span>
              <span v-if="scope.row.type === 20">下拉选择</span>
              <span v-if="scope.row.type === 30">输入数值</span>
              <span v-if="scope.row.type === 40">自动计算</span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="必填" align="center">
            <template slot-scope="scope">
              <el-checkbox
                @change="handleChange($event, scope.row)"
                v-model="scope.row.is_mandatory"
                :true-label="20"
                :false-label="10"
                :disabled="
                  scope.row.disabled ||
                  (currItem.hierarchy_id === 1 && scope.row.hierarchy_id === 1)
                "
              >
              </el-checkbox>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="date" label="标签打印" align="center">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.is_label"
                :true-label="10"
                :false-label="20"
              >
              </el-checkbox>
            </template>
          </el-table-column> -->
          <el-table-column prop="date" label="质保单选项" align="center">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.is_view"
                :true-label="10"
                :false-label="20"
              ></el-checkbox>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="date" label="入库单打印" align="center">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.is_print"
                :true-label="20"
                :false-label="10"
              ></el-checkbox>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListReq,
  getAttrListReq,
  createReq,
  detailReq,
  deleteReq,
  editReq,
  getGoodsAttrListForSaleweightReq,
  getLogListReq,
} from "@/api/goods/goodsConfig/classify";
import _ from 'lodash';
import { mixin } from '@/mixins/logs';

export default {
  name: "Classify",
  mixins: [mixin],
  data() {
    return {
      htmlNode: '<a data-key="281" data-name="TransferOut">DB20210316160420412708</a>货号调拨了',
      page: 1,
      limit: 15,
      tipToSave: false, // 是否提示用户保存
      defaultList: [], // 当销售方式为销售按克默认选中的属性值
      topSelectData: [],
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      valueId: 1,
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      treeLoading: false,
      loading2: false,
      currItem: {},
      select: [],
      loading: false,
      attr: [],
      currIndex: null,
      isAdd: true, // 当前是否为新增
      currData: [],
      attrList: [],
      currentKey: null,
      dialogVisible: false,
      type: '1',
      tableData2: {
        list: [],
        total: 0,
      },
      tableData: [{ date: "商品信息" }, { date: "饰品属性" }],
      formInline: {},
      treeData: [],
      defaultProps: {
        children: "children",
        label: "goods_type_name",
      },
    };
  },
  activated() {
    // this.getAttrList();
    this.getList();
    this.getGoodsAttrListForSaleweight();
  },
  watch: {
    formInline: {
      handler() {
        this.treeData.forEach((item, index) => {
          if (item.type === this.formInline.type) {
            this.topSelectData = [...this.treeData[index].list];
            this.topSelectData.unshift({
              goods_type_name: "无",
              goods_type_id: 0,
            });
          }
        });
      },
      deep: true,
    },
    isAdd: {
      handler(val) {
        if (val) {
          // 如果是新增，需要请求默认的分类属性数据
          this.currentKey = 0;
          this.topSelectData = [];
          this.formInline = {};
          this.getAttrList();
        }
      },
    },
    currentKey: {
      handler(val) {
        if (!this.isAdd) {
          this.formInline = { ...this.currItem };
          this.getDetail(val);
          this.getLogs();
        }
      },
    },
  },
  methods: {
    handleChange(e, row) {
      // 20为必填勾选 10为应用勾选
      if (row.is_use === 10 || row.is_mandatory === 20) {
        // 计算公式的应用或必填勾上了，公式内的属性应用或必填也要勾上
        if (row.type === 40) {
          const caclu_field_string = [];
          row.caclu_field_string.split(',').forEach((item) => {
            if (Number(item)) {
              caclu_field_string.push(Number(item));
            }
          });
          const currData = JSON.parse(JSON.stringify(this.currData));
          caclu_field_string.forEach((item) => {
            currData.forEach((data) => {
              if (data.attr_id === item) {
                data.is_use = row.is_use;
                data.is_mandatory = row.is_mandatory;
              }
            });
          });
          this.currData = currData;
        }
      }
      // 取消时公式也要取消
      if (row.is_use === 20 || row.is_mandatory === 10) {
        const currData = JSON.parse(JSON.stringify(this.currData));
        currData.forEach((data) => {
          if (data.caclu_field_string) {
            data.caclu_field_string.split(',').forEach((item) => {
              if (Number(item) === row.attr_id) {
                data.is_use = row.is_use;
                data.is_mandatory = row.is_mandatory;
              }
            });
          }
        });
        this.currData = currData;
      }
    },
    handleGroupSelect() {
      const item = { ...this.formInline };
      item.pid = 0;
      this.formInline = item;
    },
    getLogs() {
      this.logLoading = true;
      getLogListReq({
        goods_type_id: this.currItem.goods_type_id,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          this.logLoading = false;
          if (res.code === 1) {
            this.tableData2 = res.data;
          }
        })
        .catch(() => {
          this.logLoading = false;
        });
    },
    // 改变分页条数
    handleSizeChange(limit) {
      this.limit = limit;
      this.getLogs();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getLogs();
    },
    handleTipToSave() {
      this.tipToSave = true;
      this.preCurrKey = this.currentKey;
    },
    getGoodsAttrListForSaleweight() {
      getGoodsAttrListForSaleweightReq().then((res) => {
        if (res.code === 1) {
          this.defaultList = res.data;
        }
      });
    },
    // 取值
    getValue(value) {
      const formInline = { ...this.formInline };
      formInline.pid = value;
      this.formInline = formInline;
      this.handleTipToSave();
    },
    handleClick() {
      console.log(this.type);
    },
    handleSelect(val) {
      this.handleTipToSave();
      if (val === 10) {
        this.attrList[0].data.forEach((item) => {
          this.defaultList.forEach((arr) => {
            if (item.attr_id === arr.attr_id) {
              item.is_use = 10;
              item.is_mandatory = 20;
              item.disabled = true;
            }
          });
          // if (item.attr_id === 184 || item.attr_id === 185 || item.attr_id === 104 || item.attr_id === 100) {
          //   item.is_use = 10;
          //   item.is_mandatory = 20;
          //   item.disabled = true;
          // }
        });
      } else {
        this.attrList[0].data.forEach((item) => {
          item.is_use = 20;
          item.is_mandatory = 10;
          item.disabled = false;
          // if (item.attr_id === 184 || item.attr_id === 185 || item.attr_id === 104 || item.attr_id === 100) {
          //   item.disabled = false;
          // }
        });
      }
    },
    handleCancel() {
      this.tipToSave = false;
      this.getList();
    },
    handleDele() {
      if (this.currItem.hierarchy_id === 1) {
        this.$message.error("当前分类不可删除");
        return;
      }
      this.$confirm('确定要删除该分类吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteReq(this.currItem.goods_type_id).then((res) => {
            if (res.code === 1) {
              this.$message.success("删除成功");
              this.getList();
            }
          });
        })
    },
    handleEditAttr() {
      editReq({
        ...this.formInline,
        goods_type_id: this.currItem.goods_type_id,
        attr: this.attr,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tipToSave = false;
            this.$message.success("编辑成功");
            this.getList("noChange");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDetail(goods_type_id) {
      this.loading2 = true;
      detailReq(goods_type_id)
        .then((res) => {
          if (res.code === 1) {
            this.loading2 = false;
            this.attrList = res.data;
            if (this.currItem.sales_type === 10) {
              this.attrList[0].data.forEach((item) => {
                this.defaultList.forEach((arr) => {
                  if (item.attr_id === arr.attr_id) {
                    item.is_use = 10;
                    item.is_mandatory = 20;
                    item.disabled = true;
                  }
                });
                // if (item.attr_id === 184 || item.attr_id === 185 || item.attr_id === 104 || item.attr_id === 100) {
                //   item.is_use = 10;
                //   item.is_mandatory = 20;
                //   item.disabled = true;
                // }
              });
            } else {
              this.attrList[0].data.forEach((item) => {
                item.disabled = false;
                // if (item.attr_id === 184 || item.attr_id === 185 || item.attr_id === 104 || item.attr_id === 100) {
                //   item.disabled = false;
                // }
              });
            }
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    handleSave() {
      this.attr = [];
      this.attrList.forEach((item) => {
        this.attr.push(...item.data);
      });
      this.attr = this.attr.filter((item) => item.is_use === 10);
      const newArr = [];
      this.attr.forEach((item) => {
        const obj = {
          attr_id: item.attr_id,
          is_mandatory: item.is_mandatory || 20,
          is_print: item.is_print || 10,
          is_use: item.is_use,
          is_label: item.is_label || 20,
          is_view: item.is_view || 20,
          hierarchy_id: item.hierarchy_id || 0,
          goods_type_attr_id: item.goods_type_attr_id || 0,
        };
        newArr.push(obj);
      });
      this.attr = newArr;
      if (this.isAdd) {
        if (!this.attr.length) {
          this.$message.error("属性数据不能为空");
          return;
        }
        this.loading = true;
        createReq({ ...this.formInline, attr: this.attr })
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tipToSave = false;
              this.$message.success("添加成功");
              this.getList();
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.handleEditAttr();
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    handleTrue() {
      const arrtList = [...this.attrList];
      arrtList[this.currIndex].data = this.currData;
      this.attrList = arrtList;
      this.dialogVisible = false;
      this.handleTipToSave();
    },
    handleEdit(row) {
      this.currIndex = row.index;
      this.dialogVisible = true;
      const { data } = row;
      this.currData = JSON.parse(JSON.stringify(data));
    },
    getAttrList() {
      getAttrListReq().then((res) => {
        if (res.code === 1) {
          // 如果当前是新增需要处理数据
          if (this.isAdd) {
            const arrtList = [...res.data];
            res.data.forEach((bigItem, index) => {
              const newData = [];
              bigItem.data.forEach((item) => {
                const obj = { ...item };
                obj.is_use = 20;
                obj.is_mandatory = 10;
                obj.is_view = 20;
                obj.is_print = 10;
                newData.push(obj);
                arrtList[index].data = newData;
              });
            });
            this.attrList = arrtList;
          }
        }
      });
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = "├─" + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },
    getList(noChange) {
      this.treeLoading = true;
      getListReq()
        .then((res) => {
          if (res.code === 1) {
            this.treeLoading = false;
            const obj = _.groupBy(res.data, 'type');
            const arr = [];
            Object.keys(obj).forEach((key) => {
              const newObj = {
                group_name: obj[key][0].group_name,
                list: obj[key],
                type: obj[key][0].type,
              };
              arr.push(newObj);
            });
            this.treeData = arr;
            // 如果有数据，当前不是新增并且获取对应详情
            if (res.data.length) {
              this.isAdd = false;
              if (!noChange) {
                this.currentKey = this.treeData[0].list[0].goods_type_id;
                this.currItem = this.treeData[0].list[0];
              } else {
                // this.treeData = [];
                this.currentKey = this.currItem.goods_type_id;
              }
              // this.select = this.handleArr(res.data);
              // this.select.unshift({
              //   goods_type_name: "无",
              //   goods_type_id: 0,
              // });
            } else {
              this.isAdd = true;
            }
          }
        })
        .catch(() => {
          this.treeLoading = false;
        });
    },
    handleAdd() {
      this.isAdd = true;
      this.currItem = {};
      this.$message({
        message: "请填写下面信息。",
        type: "success",
      });
    },
    handleNodeClick(data) {
      if (this.isAdd) {
        if (this.tipToSave) {
          this.$confirm("新增分类暂未保存，是否确定离开?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.currItem = data;
              this.currentKey = data.goods_type_id;
              this.isAdd = false;
              this.tipToSave = false;
            })
            .catch(() => {
              this.currentKey = "";
            });
        } else {
          this.currItem = data;
          this.currentKey = data.goods_type_id;
          this.isAdd = false;
        }
      } else {
        if (this.tipToSave) {
          this.$confirm("新增分类暂未保存，是否确定离开?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.currItem = data;
              this.currentKey = data.goods_type_id;
              this.isAdd = false;
              this.tipToSave = false;
            })
            .catch(() => {
              this.currentKey = "";
              this.currentKey = this.preCurrKey;
            });
        } else {
          this.currItem = data;
          this.currentKey = data.goods_type_id;
          this.isAdd = false;
        }
      }
    },
    handleClose() {},
  },
};
</script>

<style scoped lang="less">
.tip {
  margin-bottom: 20px;
}
.red {
  color: #F56C6C;
}
.name {
  position: relative;
  margin-right: 10px;
  text-indent: 10px;
  &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 15px;
          background: #2791ff;
          left: 1px;
          top: 5px;
          border-radius: 99px;
        }
}
.tree {
      &-title {
        flex: 1;
        position: relative;
        line-height: 63px;
        text-indent: 20px;
        background: white;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 15px;
          background: #2791ff;
          left: 10px;
          top: 25px;
          border-radius: 99px;
        }
      }
    }
/deep/ .el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: "";
  position: absolute;
  background-color: #ddd;
  z-index: 1;
}
/deep/ .el-tabs__nav-wrap {
  line-height: 60px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #ecf5ff;
  // color: white;
}
/deep/ .el-tree-node__label {
  font-size: 16px;
}
/deep/ .el-tree-node__content {
  height: 40px;
  line-height: 40px;
}
.wrapper {
  .title {
    height: 58px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #f5f6fa;
    // background: linear-gradient(0deg, #f3f9ff, #ffffff);
    background: linear-gradient(0deg, #f3f9ff, #ffffff);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
    box-sizing: border-box;
    span {
      margin-right: 20px;
    }
    i {
      cursor: pointer;
      color: #999999;
    }
    &-txt {
      font-size: 16px;
      text-indent: 15px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 16px;
        background: #5494f5;
        border-radius: 2px;
        top: 5px;
        left: 0px;
      }
    }
  }
  .left {
    border-radius: 6px;
    width: 300px;
    min-height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
    background: white;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid #ddd;
    z-index: 9;
  }
  .right {
    border-radius: 6px;
    flex: 1;
    background: white;
    margin-left: 10px;
    // padding: 0 10px;
    box-sizing: border-box;
    min-height: calc(100vh - 85px);
    max-height: calc(100vh - 85px);
    overflow-y: auto;
    border: 1px solid #ddd;
    z-index: 9;
    .form {
      padding: 10px 0;
      border-bottom: 1px solid #f5f6fa;
      &-title {
        font-size: 16px;
        font-weight: bold;
        color: #999999;
        margin-bottom: 20px;
      }
    }
    .btns {
      padding: 20px 10px;
      background: linear-gradient(0deg, #f3f9ff, #ffffff);
    }
  }
}
</style>
